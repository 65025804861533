<template>
  <div class="holidayguru">
    <HgFooterNew
      class="margin"
      id="hg-footer-new"
      :lang="lang"
      :phoneNr="telephoneNumber"
      :activate-cookie-consent="!noCookieConsent"
      :agb-link="agbLink"
      :maxWidth="maxWidth"
      @onCookieButtonClick="cookieButtonClick"
      @onNewsletterButtonClick="newsletterButtonClick"
    />

    <StroeerLoader
      v-if="!noCookieConsent && !noStroeerLoader && !cookieFunction"
    />

    <GtmLoader :id="gtmId" v-if="gtmId != null" />
  </div>
</template>

<script>
import {
  HgFooterNew,
  HgFooterContactLocales
} from "@uniqgmbh/holidayguru-components"
import StroeerLoader from "../components/StroeerLoader.vue"
import GtmLoader from "../components/GtmLoader.vue"

export default {
  components: {
    HgFooterNew,
    StroeerLoader,
    GtmLoader
  },
  data: () => ({
    telephoneNumber: ""
  }),
  props: {
    noCookieConsent: {
      type: Boolean,
      default: false
    },
    noStroeerLoader: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: null
    },
    cookieFunction: {
      type: Function,
      default: null
    },
    phoneNr: {
      type: String,
      default: ""
    },
    supportMessage: {
      type: String,
      default: ""
    },
    agbLink: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: ""
    },
    gtmId: {
      type: String,
      default: null
    }
  },
  created() {
    const host = window.top.location.host

    if (this.locale) {
      this.lang = this.locale
    } else if (host.includes(".at")) {
      this.lang = "at"
    } else if (host.includes(".ch")) {
      this.lang = "ch"
    } else if (host.includes(".es")) {
      this.lang = "es"
    } else if (host.includes(".nl")) {
      this.lang = "nl"
    } else {
      this.lang = "de"
    }

    if (this.supportMessage != null && this.supportMessage.length > 0) {
      HgFooterContactLocales.de.message = this.supportMessage
      HgFooterContactLocales.at.message = this.supportMessage
      HgFooterContactLocales.ch.message = this.supportMessage
    }

    if (
      host == "kurzreisen.urlaubsguru.de" ||
      host == "kurzreisen.urlaubsguru.at" ||
      host == "kurzreisen.holidayguru.ch"
    ) {
      this.telephoneNumber = "+49 30 544455800"
    } else if (host == "rundreisen.urlaubsguru.de") {
      this.telephoneNumber = "+49 2301 8939742"
    } else if (host == "rundreisen.urlaubsguru.at") {
      this.telephoneNumber = "+43 1 3852397"
    } else if (host == "skireisen.urlaubsguru.de") {
      this.telephoneNumber = "+49 221 88828386"
    } else if (host == "skireisen.urlaubsguru.at") {
      this.telephoneNumber = "+43 12650156"
    } else if (host == "skiferien.holidayguru.ch") {
      this.telephoneNumber = "+41 445802883"
    } else if (host == "fewo.urlaubsguru.de") {
      this.telephoneNumber = "+49 2301 8939742"
    } else if (host == "fewo.urlaubsguru.at") {
      this.telephoneNumber = "+43 1 3852397"
    } else if (host == "fewo.holidayguru.ch") {
      this.telephoneNumber = "+41 4 45222063"
    } else {
      this.telephoneNumber = this.phoneNr
    }
  },
  methods: {
    newsletterButtonClick() {
      if (this.lang == "at") {
        window.location.href = "https://www.urlaubsguru.at/newsletter/"
      } else if (this.lang == "ch") {
        window.location.href = "https://www.holidayguru.ch/newsletter/"
      } else if (this.lang == "es") {
        window.location.href = "https://www.holidayguru.es/newsletter/"
      } else if (this.lang == "nl") {
        window.location.href = "https://www.holidayguru.nl/newsletter/"
      } else {
        window.location.href = "https://www.urlaubsguru.de/newsletter/"
      }
    },
    cookieButtonClick() {
      if (this.cookieFunction) {
        this.cookieFunction()
      } else {
        window._sp_.gdpr.loadPrivacyManagerModal(
          this.$i18n.messages[this.lang].privacyManagerModalId
        )
      }
    }
  }
}
</script>

<style lang="postcss">
.hg-button {
  white-space: nowrap;
}
.tw-h-12 {
  height: 3rem;
}

.margin {
  margin-top: 2.8125rem;
}
</style>
