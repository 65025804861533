<template>
  <div class="holidayguru">
    <div
      :class="
        `hg-navbar-container ${navBorder ? 'hg-navbar-container__border' : ''}`
      "
    >
      <HgNavbar
        class="hg-navbar"
        :style="navbarStyle"
        :lang="lang"
        :isMounted="mounted"
        :showBookmarkButton="false"
        :showUserButton="false"
      />
    </div>
  </div>
</template>

<script>
import { HgNavbar, HgNavbarLocales } from "@uniqgmbh/holidayguru-components"

export default {
  components: {
    HgNavbar
  },
  props: {
    locale: {
      type: String,
      default: null
    },
    navBorder: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: String,
      default: ""
    },
    supportTitle: {
      type: String,
      default: ""
    },
    supportSubline: {
      type: String,
      default: ""
    },
    supportPhone: {
      type: String,
      default: ""
    },
    supportPhoneLabel: {
      type: String,
      default: ""
    },
    supportPage: {
      type: String,
      default: ""
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navbarStyle: "",
      lang: "de",
      mounted: false
    }
  },
  created() {
    if (this.fontSize !== "") {
      this.navbarStyle += "font-size:" + this.fontSize + "; "
    }

    if (this.maxWidth !== "") {
      this.navbarStyle += "max-width:" + this.maxWidth + "; "
    }

    // Update the locale strings
    const host = window.top.location.host

    if (host == "kurzreisen.urlaubsguru.de") {
      this.lang = "de"

      HgNavbarLocales.de.logo.url = "https://www.urlaubsguru.de/"
      HgNavbarLocales.de.items[0].url = "https://www.urlaubsguru.de/angebote/"
      HgNavbarLocales.de.items[0].linkType = "a"

      HgNavbarLocales.de.supportPhone.phone = "+49 30 544455800"
      HgNavbarLocales.de.supportPhone.phoneLabel = "+49 30 544455800"
    } else if (host == "kurzreisen.urlaubsguru.at") {
      this.lang = "at"

      HgNavbarLocales.at.logo.url = "https://www.urlaubsguru.at/"
      HgNavbarLocales.at.items[0].url = "https://www.urlaubsguru.at/angebote/"
      HgNavbarLocales.at.items[0].linkType = "a"

      HgNavbarLocales.at.supportPhone.phone = "+49 30 544455800"
      HgNavbarLocales.at.supportPhone.phoneLabel = "+49 30 544455800"
    } else if (host == "kurzreisen.holidayguru.ch") {
      this.lang = "ch"

      HgNavbarLocales.ch.logo.url = "https://www.holidayguru.ch/"
      HgNavbarLocales.ch.items[0].url = "https://www.holidayguru.ch/angebote/"
      HgNavbarLocales.ch.items[0].linkType = "a"

      HgNavbarLocales.ch.supportPhone.phone = "+49 30 544455800"
      HgNavbarLocales.ch.supportPhone.phoneLabel = "+49 30 544455800"
    } else if (host == "skireisen.urlaubsguru.de") {
      this.lang = "de"

      HgNavbarLocales.de.logo.url = "https://www.urlaubsguru.de/"
      HgNavbarLocales.de.items[0].url = "https://www.urlaubsguru.de/angebote/"
      HgNavbarLocales.de.items[0].linkType = "a"

      HgNavbarLocales.de.supportPhone.phone = "+49 221 88828386"
      HgNavbarLocales.de.supportPhone.phoneLabel = "+49 221 88828386"
    } else if (host == "skireisen.urlaubsguru.at") {
      this.lang = "at"

      HgNavbarLocales.at.logo.url = "https://www.urlaubsguru.at/"
      HgNavbarLocales.at.items[0].url = "https://www.urlaubsguru.at/angebote/"
      HgNavbarLocales.at.items[0].linkType = "a"

      HgNavbarLocales.at.supportPhone.phone = "+43 12650156"
      HgNavbarLocales.at.supportPhone.phoneLabel = "+43 12650156"
    } else if (host == "skiferien.holidayguru.ch") {
      this.lang = "ch"

      HgNavbarLocales.ch.logo.url = "https://www.holidayguru.ch/"
      HgNavbarLocales.ch.items[0].url = "https://www.holidayguru.ch/angebote/"
      HgNavbarLocales.ch.items[0].linkType = "a"

      HgNavbarLocales.ch.supportPhone.phone = "+41 445802883"
      HgNavbarLocales.ch.supportPhone.phoneLabel = "+41 445802883"
    } else if (host == "fewo.urlaubsguru.de") {
      this.lang = "de"

      HgNavbarLocales.de.logo.url = "https://www.urlaubsguru.de/"
      HgNavbarLocales.de.items[0].url = "https://www.urlaubsguru.de/angebote/"
      HgNavbarLocales.de.items[0].linkType = "a"

      HgNavbarLocales.de.supportPhone.phone = "+49 2301 8939742"
      HgNavbarLocales.de.supportPhone.phoneLabel = "+49 2301 8939742"
    } else if (host == "fewo.urlaubsguru.at") {
      this.lang = "at"

      HgNavbarLocales.at.logo.url = "https://www.urlaubsguru.at/"
      HgNavbarLocales.at.items[0].url = "https://www.urlaubsguru.at/angebote/"
      HgNavbarLocales.at.items[0].linkType = "a"

      HgNavbarLocales.at.supportPhone.phone = "+43 1 3852397"
      HgNavbarLocales.at.supportPhone.phoneLabel = "+43 1 3852397"
    } else if (host == "fewo.holidayguru.ch") {
      this.lang = "ch"

      HgNavbarLocales.ch.logo.url = "https://www.holidayguru.ch/"
      HgNavbarLocales.ch.items[0].url = "https://www.holidayguru.ch/angebote/"
      HgNavbarLocales.ch.items[0].linkType = "a"

      HgNavbarLocales.ch.supportPhone.phone = "+41 4 45222063"
      HgNavbarLocales.ch.supportPhone.phoneLabel = "+41 4 45222063"
    } else if (host.includes(".at") || this.locale == "at") {
      this.lang = "at"

      HgNavbarLocales.at.logo.url = "https://www.urlaubsguru.at/"
      HgNavbarLocales.at.items[0].url = "https://www.urlaubsguru.at/angebote/"
      HgNavbarLocales.at.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.at.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.at.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.at.supportPhone.phone = this.supportPhone
        HgNavbarLocales.at.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.at.showSupportPage = true
        HgNavbarLocales.at.supportPageUrl = this.supportPage
      }
    } else if (host.includes(".ch") || this.locale == "ch") {
      this.lang = "ch"

      HgNavbarLocales.ch.logo.url = "https://www.holidayguru.ch/"
      HgNavbarLocales.ch.items[0].url = "https://www.holidayguru.ch/angebote/"
      HgNavbarLocales.ch.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.ch.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.ch.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.ch.supportPhone.phone = this.supportPhone
        HgNavbarLocales.ch.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.ch.showSupportPage = true
        HgNavbarLocales.ch.supportPageUrl = this.supportPage
      }
    } else if (host.includes(".nl") || this.locale == "nl") {
      this.lang = "nl"

      HgNavbarLocales.nl.logo.url = "https://www.holidayguru.nl/"
      HgNavbarLocales.nl.items[0].url =
        "https://www.holidayguru.nl/aanbiedingen/"
      HgNavbarLocales.nl.items[0].linkType = "a"

      if (this.supportTitle && this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.nl.showSupportPhone = true
        HgNavbarLocales.nl.supportPhone = {
          title: this.supportTitle,
          subline: this.supportSubline,
          phone: this.supportPhone,
          phoneLabel: this.supportPhoneLabel
        }
      }

      if (this.supportPage) {
        HgNavbarLocales.nl.showSupportPage = true
        HgNavbarLocales.nl.supportPageUrl = this.supportPage
      }
    } else if (host.includes(".es") || this.locale == "es") {
      this.lang = "es"

      HgNavbarLocales.es.logo.url = "https://www.holidayguru.es/"
      HgNavbarLocales.es.items[0].url = "https://www.holidayguru.es/deals/"
      HgNavbarLocales.es.items[0].linkType = "a"

      if (this.supportTitle && this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.es.showSupportPhone = true
        HgNavbarLocales.es.supportPhone = {
          title: this.supportTitle,
          subline: this.supportSubline,
          phone: this.supportPhone,
          phoneLabel: this.supportPhoneLabel
        }
      }

      if (this.supportPage) {
        HgNavbarLocales.es.showSupportPage = true
        HgNavbarLocales.es.supportPageUrl = this.supportPage
      }
    } else {
      HgNavbarLocales.de.logo.url = "https://www.urlaubsguru.de/"
      HgNavbarLocales.de.items[0].url = "https://www.urlaubsguru.de/angebote/"
      HgNavbarLocales.de.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.de.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.de.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.de.supportPhone.phone = this.supportPhone
        HgNavbarLocales.de.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.de.showSupportPage = true
        HgNavbarLocales.de.supportPageUrl = this.supportPage
      }
    }
  },
  mounted() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.mounted = true
      }, this.delay)
    } else {
      this.mounted = true
    }
  }
}
</script>

<style>
.hg-navbar-header {
  width: 180px;
}
@media screen and (min-width: 768px) {
  .hg-navbar-header {
    width: 240px;
  }
}

.hg-navbar-container {
  width: 100%;
  background-color: white;
  min-height: 54px;
}

@media (min-width: 1024px) {
  .hg-navbar-container {
    min-height: 61px;
  }
}

.hg-navbar {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  z-index: 50;
}

.hg-navbar-container__border {
  border-bottom: 4px solid #dcfaf5;
}

.hg-deals-heading-nav a {
  width: 100%;
}

.hg-header__nav-mobile-container {
  display: none;
}

.hg-header__nav-mobile-container--active {
  display: block;
}
</style>
